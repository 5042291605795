import React, { useState } from "react";

const GifWrapper = ({ animation, poster, alt = "" }) => {
  const [activeImage, toggleActiveImage] = useState(poster || animation);

  const buttonStyle = {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    height: "100%",
    width: "100%",
    position: "absolute",
    left: "0",
    top: "0"
  };

  return (
    <div style={{ position: "relative" }}>
      {poster ? (
        <button
          style={buttonStyle}
          aria-label={`Play ${alt}`}
          onClick={() => {
            const toggle = activeImage === poster ? animation : poster;
            toggleActiveImage(toggle);
          }}
        ></button>
      ) : (
        ""
      )}
      <img src={activeImage} alt="" style={{ width: "100%" }} />
    </div>
  );
};

export default GifWrapper;
