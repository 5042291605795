import DisclamerBox from './disclamerBox'

const InWritingMessage = () => (
    <DisclamerBox>
        This content is still in the writing process
        <br />
        it will be published soon
    </DisclamerBox>
)

export default InWritingMessage;