import * as React from "react";
import "./sv-components.css";

const AFFECTS_NAMES = {
    SR: "Screen Reader",
    KB: "Keyboard",
    VC: "Voice Control",
    LV: "Low Vision",
    COG: "Cognitive",
    AD: "Attention Deficit",
    DE: "Deaf",
    CB: "Color Blind",
    MOT: "Motor/Mobility",
    DYS: "Dyslexia",
    BR: "Braille",
    SC: "Switch Control"
};

const AffectsBox = ({ ...props }) => {
    if (props.children) {
        return (
            <div className="affects-box-outer-wrapper">
                Who might be affected
                <div className="affects-box-wrapper">
                    {props.children.split(",").map(affect => {
                        return <div tabIndex="0" key={affect}>{AFFECTS_NAMES[affect]}</div>;
                    })}
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default AffectsBox;
