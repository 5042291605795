const config = {
    gatsby: {
        pathPrefix: "/",
        siteUrl: "https://knowledge.evinced.com/",
        gaTrackingId: process.env.GA_TRACK_ID
    },
    header: {
        logo: "",
        logoLink: "/",
        title: "Digital Accessibility Knowledge Base",
        githubUrl: "",
        helpUrl: "",
        tweetText: "Evinced, Inc.'s Accessibility Knowledge Base",
        links: [{ text: "", link: "" }],
        search: {
            enabled: true,
            indexName: "Pages",
            algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
            algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
            algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY
        }
    },
    footer: {
        links: [
            { text: "products", link: "https://www.evinced.com/products" },
            { text: "Knowledge Base", link: "/" },
            { text: "Blog", link: "https://www.evinced.com/blog/" },
            { text: "about us", link: "https://www.evinced.com/about" }
        ]
    },
    sidebar: {
        forcedNavOrder: [
            "system-validations",
            "mobile-validations",
            "/elements",
            "/concepts",
            "components"
        ],
        collapsedNav: [
            "/system-validations",
            "/mobile-validations",
            "/elements",
            "/elements/widgets",
            "/elements/page-structure",
            "/elements/landmarks",
            "/concepts",
            "/components"
        ],
        links: [
            { text: "Evinced, Inc.", link: "https://evinced.com" },
            { text: "Blog", link: "https://evinced.com/blog" }
        ],
        frontline: false,
        ignoreIndex: true,
        ignoreSections: ["/tbd"]
    },
    siteMetadata: {
        title: "Accessibility Knowledge Base | Evinced, Inc.",
        description:
            "An accessibility Knowledge Base for the Evinced, Inc. accessibility tests suite",
        ogImage: null,
        docsLocation: "https://github.com/GetEvinced/evinced-kb",
        favicon: "../favicon/Evinced_logo_symbol_left.png"
    },
    pwa: {
        enabled: false, // disabling this will also remove the existing service worker.
        manifest: {
            name: "Evinced Knowledge Base",
            short_name: "EvincedKB",
            start_url: "/",
            background_color: "#6b37bf",
            theme_color: "#6b37bf",
            display: "standalone",
            crossOrigin: "use-credentials",
            icons: [
                {
                    src: "src/pwa-512.png",
                    sizes: `512x512`,
                    type: `image/png`
                }
            ]
        }
    }
};

module.exports = config;
