import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import Link from "./link";
import "./styles.css";
import config from "../../config";

import ClosedSvg from "./images/closed";
const forcedNavOrder = config.sidebar.forcedNavOrder;

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');
}

const Sidebar = styled("nav")`
    border-left: 0.1rem solid #ccc;
    height: 100vh;
    overflow-y: auto;
    position: sticky;
    top: 0;
    height: 100vh;

    @media only screen and (max-width: 50rem) {
        width: 100%;
        position: relative;
    }
`;

function trimTrailingSlash(str) {
    return str.replace(/\/$/, "");
}

const setToc = pageSections =>
    pageSections.map((section, index) => {
        const internalPath =
            typeof document !== "undefined" ? document.location.pathname : "";
        const sectionId = section.title
            ? section.title.replace(/\s+/g, "-").toLowerCase()
            : "#";
        if (section.items && section.items.length > 0) {
            return (
                <ListItem key={index} level={1}>
                    <Link to={`${internalPath}#${sectionId}`}>
                        {section.title}
                    </Link>
                    <ul>{setToc(section.items)}</ul>
                </ListItem>
            );
        }
        return (
            <ListItem key={index} level={1}>
                <Link to={`${internalPath}#${sectionId}`}>{section.title}</Link>
            </ListItem>
        );
    });

// eslint-disable-next-line no-unused-vars
const ListItem = styled(({ className, active, level, ...props }) => {
    return <li className={className} {...props} />;
})`
    list-style: none;

    a {
        text-decoration: none;
        padding: 0;
        display: inline-block;
        position: relative;
        vertical-align: middle;

        ${props =>
            props.active &&
            `
      color: #663399;
      border-color: rgb(230,236,241) !important;
      border-style: solid none solid solid;
      border-width: 1px 0px 1px 1px;
      background-color: #fff;
    `} // external link icon
    svg {
            float: right;
            margin-right: 1rem;
        }
    }
`;

const SidebarLayout = ({ location }) => (
    <StaticQuery
        query={graphql`
            query {
                allMdx {
                    edges {
                        node {
                            fields {
                                slug
                            }
                            tableOfContents
                        }
                    }
                }
            }
        `}
        render={({ allMdx }) => {
            let navItems = [];
            let finalNavItems;
            if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
                const navItems = allMdx.edges.map((item, index) => {
                    let innerItems;
                    if (item !== undefined) {
                        if (
                            trimTrailingSlash(item.node.fields.slug) ===
                                trimTrailingSlash(location.pathname) ||
                            config.gatsby.pathPrefix +
                                trimTrailingSlash(item.node.fields.slug) ===
                                trimTrailingSlash(location.pathname)
                        ) {
                            innerItems = item.node.tableOfContents.items
                                ? setToc(item.node.tableOfContents.items)
                                : null;
                        }
                    }
                    if (innerItems) {
                        finalNavItems = innerItems;
                    }
                });
            }

            if (finalNavItems && finalNavItems.length) {
                return (
                    <Sidebar aria-label="page table of contents">
                        <ul className={"rightSideBarUL"}>
                            <li className={"rightSideTitle"}>CONTENTS</li>
                            {finalNavItems}
                        </ul>
                    </Sidebar>
                );
            }
        }}
    />
);

export default SidebarLayout;
